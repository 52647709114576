<script setup lang="ts">
import { computed, ref } from "vue";

import BrandLogoFull from "@/components/icons/BrandLogoFull.vue";
import ExpandIcon from "~/components/nav/ExpandIcon.vue";

import NavLinkList from "~/components/nav/NavLinkList.vue";
import BrandIcon from "@/components/icons/BrandIcon.vue";
import { useSideNavStore } from "~/store/sideNavStore";

const sideNavStore = useSideNavStore();

const visible = computed(() => sideNavStore.visible);
const expanded = computed(() => sideNavStore.expanded);

watch(visible, () => {
  if (visible.value) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = null;
  }
});

const sidenavContainer = ref<HTMLDivElement>();

const navClasses = computed(() => {
  const classes = [expanded.value ? "w-4/5 md:w-96" : "w-20"];
  if (!visible.value) {
    classes.push("-translate-x-full lg:translate-x-0");
  }

  return classes.join(" ");
});
</script>

<template>
  <nav class="fixed h-dvh select-none text-sm" :class="{ 'pointer-events-none lg:pointer-events-auto': !visible }">
    <!-- gray background -->
    <Transition>
      <div
        class="fixed bottom-0 left-0 right-0 top-0 grow bg-black opacity-50 lg:hidden"
        v-if="visible"
        @click="sideNavStore.hide()"
      />
    </Transition>

    <div class="pointer-events-none fixed top-0 flex h-full lg:static">
      <!-- main side nav -->
      <div class="pointer-events-auto flex h-full flex-col bg-primary-darker transition-all" :class="navClasses">
        <!-- nav top -->
        <div class="flex min-h-0 flex-1 flex-col justify-between gap-y-2">
          <!--logo-->
          <div class="flex-none overflow-x-clip px-4 py-3">
            <NuxtLink to="/" class="w-full flex-1 overflow-x-clip" aria-label="home">
              <div class="relative flex h-10 w-full">
                <Transition>
                  <BrandLogoFull class="absolute w-[106px]" v-if="expanded" />
                </Transition>

                <BrandIcon class="absolute w-[48px]" />
              </div>
            </NuxtLink>
            <!--Expand/Collaspe -->
            <div class="flex-1">
              <div class="flex justify-end">
                <button
                  @click="sideNavStore.toggleExpanded()"
                  class="p-3 hover:bg-gray-800"
                  aria-label="Expand/Collapse Side Nav"
                >
                  <ExpandIcon
                    class="h-5 w-5 fill-white transition-all"
                    :class="{ '-rotate-90': !expanded, 'rotate-90': expanded }"
                  />
                </button>
              </div>
            </div>
          </div>

          <!-- Navigation Links -->
          <div class="basis-90 scrollbar-color-primary-darker flex-1 overflow-y-auto pb-10" ref="sidenavContainer">
            <NavLinkList />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 350ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.scrollbar-color-primary-darker {
  scrollbar-color: #4b5563 #374151;
}
</style>
