<script setup lang="ts">
import type { PropType } from "vue";
import { computed, ref } from "vue";
import kebabCase from "lodash/kebabCase";
import ChevronDown from "~/components/icons/ChevronDown.vue"; // import CaratIcon from "~/components/icons/CaratIcon.vue";

// import CaratIcon from "~/components/icons/CaratIcon.vue";

interface NavItem {
  title: string;
  _path?: string;
  children?: Array<NavItem>;
}

const props = defineProps({
  navItem: {
    type: Object as PropType<NavItem>,
    required: true,
  },
  depth: {
    type: Number,
    default: 0,
  },
  baseUrl: {
    type: String,
    default: "",
  },
  currentUrl: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["click-link"]);
const route = useRoute();

// Class styles based on nesting depth, from depth 0 to 3
const depthClasses = ["pl-10", "pl-16 text-sm", "p-24 text-sm", "pl-32 text-sm"];

// get a default URL if one is not provided
const url = props.navItem._path ? props.navItem._path : kebabCase(props.navItem.label);

// calculate the URL for this element
const href = url;

// handling for expand/collapse
const isExpanded = ref(false);
const gridTemplateRows = computed(() => (isExpanded.value ? "grid-rows-[1fr]" : "grid-rows-[0fr]"));

const borderLeftClass = "border-l border-gray-500 hover:border-l-schema hover:bg-gray-700 ml-1.5 side-nav-py";
const borderLeftClassActive = computed(() => (route.path === `/docs${url}` ? "border-l-schema bg-gray-700" : ""));
const activeLinkClass = computed(() => (route.path === href ? "bg-gray-700" : ""));

const handleClickCollapse = () => {
  emit("click-link");
};
</script>

<template>
  <div>
    <!--    Nav item has no children-->
    <NuxtLink
      v-if="!navItem.children"
      :to="href"
      class="block"
      :class="[depthClasses[depth], borderLeftClass, borderLeftClassActive, activeLinkClass]"
      @click="handleClickCollapse"
    >
      {{ navItem.title }}
    </NuxtLink>

    <!--    Nav item has children -->
    <div v-if="navItem.children">
      <button
        @click.stop="isExpanded = !isExpanded"
        class="w-full text-left"
        :class="[depthClasses[depth], borderLeftClass]"
      >
        {{ navItem.title }}
        <ChevronDown class="float-right mr-4 h-5 w-5 fill-white" :class="{ '-rotate-90': !isExpanded }" />

        <span :class="!isExpanded ? '-rotate-90' : ''" class="transition-all" />
      </button>

      <!--    Render children-->
      <div class="grid overflow-hidden transition-all" :class="gridTemplateRows">
        <div class="min-h-0">
          <div>
            <TableOfContentsItem
              :depth="depth + 1"
              v-for="item in navItem.children"
              :key="item.title"
              :nav-item="item"
              :base-url="href"
              :current-url="currentUrl"
              @click-link="emit('click-link')"
            ></TableOfContentsItem>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
