<script setup lang="ts">
import HamburgerButton from "~/components/nav/HamburgerButton.vue";
import { computed } from "vue";
import { useSideNavStore } from "~/store/sideNavStore";
import BrandLogoFull from "~/components/icons/BrandLogoFull.vue";

const visible = computed(() => useSideNavStore().visible);

// Watch for router changes to close the side nav on mobile
const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    // Check for window width, if less than 1024px, hide the side nav
    if (window.innerWidth < 1024) {
      useSideNavStore().hide();
    }
  },
);
</script>

<template>
  <div class="sticky top-0 z-10">
    <!-- Top nav bar -->
    <div class="flex h-16 items-center justify-between bg-primary-darker">
      <!-- Mobile hamburger button -->
      <HamburgerButton
        class="h-full cursor-pointer px-4"
        :sidenav-open="visible"
        @click="useSideNavStore().toggle()"
        aria-label="Open Menu"
      />

      <NuxtLink to="/" class="flex h-full items-center px-6" aria-label="home">
        <BrandLogoFull class="h-auto w-24" />
      </NuxtLink>
    </div>
  </div>
</template>
