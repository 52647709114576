<script setup lang="ts">
import SideNav from "~/components/nav/SideNav.vue";
import MobileTopNav from "~/components/nav/MobileTopNav.vue";
import { computed } from "vue";
import { useSideNavStore } from "~/store/sideNavStore";

const sideNavExpanded = computed(() => useSideNavStore().expanded);

const marginLeft = computed(() => (sideNavExpanded.value ? "lg:pl-96" : "lg:pl-20"));
</script>

<template>
  <div>
    <!-- Mobile top nav bar -->
    <MobileTopNav class="lg:hidden" />

    <!-- Side nav -->
    <SideNav class="fixed top-0 z-10 lg:fixed lg:left-0 lg:w-fit" />

    <!-- Page Content -->
    <main id="main" class="z-0 transition-all" :class="marginLeft">
      <slot />
    </main>
  </div>
</template>
