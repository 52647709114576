<script setup lang="ts">
import type { PropType } from "vue";
import type { NavLinkWithHref } from "@/Types/NavLink";
import { useElementBounding } from "@vueuse/core";
import NavCollapseItem from "~/components/nav/NavCollapseItem.vue";
import TableOfContents from "~/components/docs/TableOfContents.vue";

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  expanded: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: Object,
    default: null,
  },
  active: {
    type: Boolean,
    default: false,
  },
  children: {
    type: Array as PropType<NavLinkWithHref[]>,
    default: () => [],
  },
  useDocsTOC: {
    type: Boolean,
    default: false,
  },
});

const open = ref(true);
const menuButton = ref<HTMLButtonElement>();

const collapse = () => {
  open.value = false;
};

const expand = () => {
  open.value = true;
};

function toggleShow() {
  if (open.value) {
    collapse();
  } else {
    expand();
  }
}

function barColor(active = false) {
  return active ? "bg-schema" : "";
}

const classes = computed(() => (props.active ? "bg-gray-700 font-bold" : "font-light"));

const { top: buttonTop, width: buttonWidth } = useElementBounding(menuButton);
</script>

<template>
  <div class="">
    <button
      class="relative block w-full text-white hover:bg-primary-lighter"
      @click="toggleShow"
      ref="menuButton"
      :class="classes"
    >
      <span class="absolute left-0 h-full w-2" :class="barColor(active)" />

      <span class="side-nav-py block w-full px-7">
        <span class="flex items-center justify-start gap-x-3 overflow-clip">
          <Component :is="icon" class="h-5 w-5 flex-none fill-white" />

          <span class="font-light transition-all" :class="{ '': expanded, 'opacity-0': !expanded }">
            {{ label }}
          </span>
        </span>
      </span>
    </button>

    <div v-if="open && expanded">
      <div class="box-border flex flex-col overflow-hidden text-white">
        <template v-if="!useDocsTOC">
          <NavCollapseItem
            v-for="child in children"
            :key="child.href"
            :href="child.href"
            :icon="child.icon"
            :label="child.label"
            :active="child.active"
          />
        </template>

        <TableOfContents v-else />
      </div>
    </div>
  </div>
</template>
