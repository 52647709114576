<script setup lang="ts"></script>

<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 113 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
  >
    <path
      d="M11.642,5.94c-0.097,-0.358 -0.239,-0.673 -0.426,-0.946c-0.188,-0.278 -0.418,-0.514 -0.69,-0.707c-0.273,-0.193 -0.586,-0.338 -0.938,-0.435c-0.352,-0.102 -0.739,-0.153 -1.159,-0.153c-0.847,-0 -1.58,0.204 -2.199,0.614c-0.614,0.409 -1.088,1.005 -1.423,1.789c-0.335,0.779 -0.503,1.725 -0.503,2.838c-0,1.12 0.162,2.074 0.486,2.864c0.324,0.79 0.792,1.392 1.406,1.807c0.614,0.415 1.358,0.622 2.233,0.622c0.773,-0 1.426,-0.125 1.96,-0.375c0.54,-0.256 0.949,-0.617 1.227,-1.082c0.279,-0.466 0.418,-1.015 0.418,-1.645l0.784,0.093l-4.244,0l-0,-3.076l7.525,-0l0,2.309c0,1.563 -0.332,2.901 -0.997,4.015c-0.659,1.113 -1.568,1.968 -2.727,2.565c-1.153,0.596 -2.48,0.895 -3.98,0.895c-1.665,-0 -3.128,-0.361 -4.389,-1.083c-1.262,-0.721 -2.245,-1.75 -2.949,-3.085c-0.705,-1.341 -1.057,-2.932 -1.057,-4.773c0,-1.431 0.213,-2.701 0.639,-3.809c0.426,-1.114 1.02,-2.057 1.781,-2.83c0.768,-0.772 1.654,-1.358 2.66,-1.755c1.011,-0.398 2.099,-0.597 3.264,-0.597c1.011,-0 1.951,0.145 2.821,0.435c0.875,0.289 1.647,0.699 2.318,1.227c0.676,0.528 1.224,1.156 1.645,1.883c0.42,0.728 0.682,1.526 0.784,2.395l-4.27,0Z"
      style="fill: #fff; fill-rule: nonzero"
    />

    <path
      d="M18.586,17.693l-0,-17.454l7.21,-0c1.307,-0 2.435,0.235 3.384,0.707c0.954,0.466 1.69,1.136 2.207,2.011c0.517,0.87 0.776,1.901 0.776,3.094c-0,1.21 -0.265,2.239 -0.793,3.085c-0.528,0.841 -1.278,1.483 -2.25,1.926c-0.972,0.438 -2.122,0.657 -3.452,0.657l-4.559,-0l-0,-3.324l3.775,-0c0.637,-0 1.168,-0.082 1.594,-0.247c0.432,-0.171 0.759,-0.426 0.98,-0.767c0.222,-0.347 0.332,-0.79 0.332,-1.33c0,-0.54 -0.11,-0.986 -0.332,-1.338c-0.221,-0.358 -0.548,-0.625 -0.98,-0.801c-0.432,-0.182 -0.963,-0.273 -1.594,-0.273l-2.079,0l-0,14.054l-4.219,0Zm9.827,-7.977l4.346,7.977l-4.602,0l-4.261,-7.977l4.517,-0Z"
      style="fill: #fff; fill-rule: nonzero"
    />

    <path
      d="M50.32,6.563l-4.261,-0c-0.057,-0.438 -0.173,-0.833 -0.349,-1.185c-0.177,-0.352 -0.41,-0.654 -0.699,-0.904c-0.29,-0.25 -0.634,-0.44 -1.032,-0.571c-0.392,-0.136 -0.826,-0.204 -1.304,-0.204c-0.846,-0 -1.576,0.207 -2.19,0.622c-0.608,0.415 -1.077,1.014 -1.406,1.798c-0.324,0.784 -0.486,1.733 -0.486,2.847c0,1.159 0.165,2.131 0.494,2.915c0.336,0.778 0.804,1.366 1.407,1.764c0.608,0.392 1.326,0.588 2.156,0.588c0.466,-0 0.889,-0.06 1.27,-0.179c0.386,-0.119 0.724,-0.293 1.014,-0.52c0.295,-0.233 0.537,-0.514 0.724,-0.844c0.194,-0.335 0.327,-0.713 0.401,-1.133l4.261,0.025c-0.074,0.773 -0.298,1.534 -0.673,2.284c-0.369,0.75 -0.878,1.435 -1.526,2.054c-0.647,0.614 -1.437,1.103 -2.369,1.466c-0.926,0.364 -1.989,0.546 -3.187,0.546c-1.58,-0 -2.995,-0.347 -4.245,-1.04c-1.244,-0.699 -2.227,-1.716 -2.949,-3.051c-0.721,-1.335 -1.082,-2.96 -1.082,-4.875c0,-1.921 0.367,-3.548 1.099,-4.884c0.733,-1.335 1.725,-2.349 2.975,-3.042c1.25,-0.693 2.65,-1.04 4.202,-1.04c1.056,0 2.034,0.148 2.931,0.443c0.898,0.29 1.688,0.716 2.37,1.279c0.682,0.556 1.236,1.241 1.662,2.054c0.426,0.812 0.69,1.741 0.792,2.787Z"
      style="fill: #fff; fill-rule: nonzero"
    />

    <g>
      <path
        d="M57.257,9.664l0,-1.391c0,-0.229 0.042,-0.393 0.125,-0.492c0.089,-0.099 0.229,-0.148 0.422,-0.148c0.213,-0 0.383,0.146 0.508,0.437c0.083,0.177 0.159,0.31 0.226,0.399c0.219,0.281 0.542,0.508 0.969,0.679c0.427,0.167 0.899,0.25 1.414,0.25c0.552,0 0.995,-0.112 1.328,-0.335c0.334,-0.224 0.5,-0.519 0.5,-0.883c0,-0.37 -0.135,-0.633 -0.406,-0.789c-0.266,-0.157 -0.724,-0.235 -1.375,-0.235l-0.43,0c-1.062,0 -1.872,-0.185 -2.429,-0.554c-0.558,-0.37 -0.836,-0.907 -0.836,-1.61c-0,-0.687 0.271,-1.221 0.812,-1.601c0.547,-0.381 1.313,-0.571 2.297,-0.571c0.401,0 0.81,0.058 1.227,0.172c0.416,0.11 0.635,0.164 0.656,0.164c0.057,0 0.167,-0.049 0.328,-0.148c0.161,-0.104 0.32,-0.156 0.477,-0.156c0.177,-0 0.307,0.065 0.39,0.195c0.089,0.13 0.133,0.325 0.133,0.586l0,0.914c0,0.276 -0.042,0.476 -0.125,0.601c-0.083,0.12 -0.216,0.18 -0.398,0.18c-0.157,0 -0.349,-0.133 -0.579,-0.398c-0.161,-0.183 -0.294,-0.321 -0.398,-0.414c-0.276,-0.256 -0.57,-0.438 -0.883,-0.547c-0.312,-0.115 -0.679,-0.172 -1.101,-0.172c-0.49,-0 -0.886,0.109 -1.188,0.328c-0.297,0.214 -0.445,0.487 -0.445,0.82c-0,0.328 0.153,0.563 0.461,0.703c0.307,0.141 0.969,0.222 1.984,0.243c1.083,0.026 1.875,0.216 2.375,0.57c0.5,0.349 0.75,0.891 0.75,1.625c0,0.703 -0.276,1.266 -0.828,1.687c-0.552,0.422 -1.297,0.633 -2.234,0.633c-0.297,0 -0.628,-0.028 -0.993,-0.086c-0.364,-0.052 -0.822,-0.143 -1.375,-0.273c-0.182,0.12 -0.338,0.208 -0.468,0.265c-0.131,0.063 -0.24,0.094 -0.328,0.094c-0.183,0 -0.323,-0.062 -0.422,-0.187c-0.094,-0.13 -0.141,-0.315 -0.141,-0.555Z"
        style="fill: #35f3ff; fill-rule: nonzero"
      />

      <path
        d="M72.624,3.477l0,-0.032c0,-0.172 0.05,-0.297 0.149,-0.375c0.099,-0.083 0.252,-0.125 0.461,-0.125c0.213,0 0.359,0.063 0.437,0.188c0.083,0.125 0.125,0.383 0.125,0.773l0,1.453c0,0.193 -0.049,0.339 -0.148,0.438c-0.099,0.094 -0.248,0.141 -0.446,0.141c-0.14,-0 -0.263,-0.04 -0.367,-0.118c-0.099,-0.083 -0.221,-0.252 -0.367,-0.507c-0.25,-0.422 -0.555,-0.74 -0.914,-0.954c-0.354,-0.218 -0.763,-0.328 -1.227,-0.328c-0.812,0 -1.474,0.24 -1.984,0.719c-0.505,0.479 -0.758,1.099 -0.758,1.859c0,0.776 0.242,1.412 0.727,1.907c0.489,0.489 1.117,0.734 1.883,0.734c0.349,-0 0.679,-0.047 0.992,-0.141c0.317,-0.099 0.617,-0.244 0.898,-0.437c0.099,-0.063 0.232,-0.159 0.399,-0.289c0.338,-0.276 0.604,-0.414 0.797,-0.414c0.161,-0 0.291,0.052 0.39,0.156c0.104,0.099 0.156,0.229 0.156,0.391c0,0.411 -0.39,0.833 -1.171,1.265c-0.777,0.427 -1.602,0.641 -2.477,0.641c-1.13,-0 -2.073,-0.362 -2.828,-1.086c-0.75,-0.724 -1.125,-1.628 -1.125,-2.711c-0,-1.083 0.375,-1.987 1.125,-2.711c0.75,-0.729 1.687,-1.094 2.812,-1.094c0.396,0 0.792,0.055 1.188,0.164c0.401,0.105 0.825,0.269 1.273,0.493Z"
        style="fill: #35f3ff; fill-rule: nonzero"
      />

      <path
        d="M76.945,9.125l-0,-8l-0.149,0c-0.354,0 -0.599,-0.042 -0.734,-0.125c-0.13,-0.083 -0.196,-0.229 -0.196,-0.437c0,-0.188 0.053,-0.329 0.157,-0.422c0.109,-0.094 0.265,-0.141 0.468,-0.141l1.188,0c0.141,0 0.25,0.029 0.328,0.086c0.078,0.057 0.117,0.135 0.117,0.234l0,3.539c0.38,-0.354 0.755,-0.614 1.125,-0.781c0.375,-0.172 0.763,-0.258 1.164,-0.258c0.787,0 1.404,0.245 1.852,0.735c0.453,0.489 0.68,1.166 0.68,2.031l-0,3.539l0.367,0c0.26,0 0.448,0.044 0.562,0.133c0.115,0.083 0.172,0.219 0.172,0.406c0,0.188 -0.052,0.328 -0.156,0.422c-0.104,0.094 -0.261,0.141 -0.469,0.141l-2.078,-0c-0.245,-0 -0.411,-0.04 -0.5,-0.118c-0.083,-0.083 -0.125,-0.231 -0.125,-0.445c0,-0.208 0.052,-0.349 0.156,-0.422c0.11,-0.078 0.313,-0.117 0.61,-0.117l0.273,0l0,-3.25c0,-0.609 -0.138,-1.073 -0.414,-1.391c-0.276,-0.323 -0.677,-0.484 -1.203,-0.484c-0.537,0 -1.008,0.18 -1.414,0.539c-0.401,0.359 -0.602,0.805 -0.602,1.336l0,3.25l0.274,0c0.312,0 0.523,0.039 0.633,0.117c0.109,0.078 0.164,0.219 0.164,0.422c-0,0.214 -0.045,0.362 -0.133,0.445c-0.084,0.078 -0.248,0.118 -0.492,0.118l-2.079,-0c-0.203,-0 -0.356,-0.047 -0.46,-0.141c-0.099,-0.094 -0.149,-0.234 -0.149,-0.422c0,-0.208 0.06,-0.349 0.18,-0.422c0.125,-0.078 0.364,-0.117 0.719,-0.117l0.164,0Z"
        style="fill: #35f3ff; fill-rule: nonzero"
      />

      <path
        d="M86.851,6.961c0.041,0.75 0.294,1.331 0.758,1.742c0.468,0.406 1.106,0.609 1.914,0.609c0.609,0 1.273,-0.161 1.992,-0.484c0.719,-0.328 1.154,-0.492 1.305,-0.492c0.156,-0 0.286,0.049 0.39,0.148c0.104,0.099 0.156,0.222 0.156,0.368c0,0.151 -0.054,0.289 -0.164,0.414c-0.109,0.125 -0.283,0.247 -0.523,0.367c-0.521,0.255 -1.06,0.45 -1.617,0.586c-0.552,0.135 -1.102,0.203 -1.649,0.203c-1.187,-0 -2.138,-0.341 -2.851,-1.024c-0.714,-0.687 -1.071,-1.596 -1.071,-2.726c0,-1.151 0.378,-2.083 1.133,-2.797c0.755,-0.714 1.74,-1.07 2.953,-1.07c1.099,-0 2.011,0.333 2.735,1c0.724,0.666 1.086,1.474 1.086,2.422c-0,0.291 -0.063,0.487 -0.188,0.586c-0.12,0.098 -0.45,0.148 -0.992,0.148l-5.367,-0Zm0.039,-1.023l5.086,-0c-0.078,-0.62 -0.339,-1.118 -0.781,-1.493c-0.438,-0.38 -0.977,-0.57 -1.618,-0.57c-0.692,0 -1.276,0.182 -1.75,0.547c-0.474,0.364 -0.786,0.87 -0.937,1.516Z"
        style="fill: #35f3ff; fill-rule: nonzero"
      />

      <path
        d="M101.999,5.875c0,-0.734 -0.068,-1.229 -0.203,-1.484c-0.131,-0.261 -0.357,-0.391 -0.68,-0.391c-0.385,0 -0.693,0.167 -0.922,0.5c-0.224,0.333 -0.335,0.792 -0.335,1.375l-0,3.25l0.14,0c0.318,0 0.528,0.039 0.633,0.117c0.109,0.073 0.164,0.214 0.164,0.422c0,0.188 -0.045,0.328 -0.133,0.422c-0.089,0.094 -0.224,0.141 -0.406,0.141l-0.992,-0c-0.224,-0 -0.378,-0.053 -0.461,-0.157c-0.078,-0.104 -0.117,-0.328 -0.117,-0.672l-0,-4.132c-0,-0.391 -0.089,-0.698 -0.266,-0.922c-0.177,-0.229 -0.419,-0.344 -0.726,-0.344c-0.235,0 -0.451,0.07 -0.649,0.211c-0.193,0.135 -0.359,0.338 -0.5,0.609l0,4.305l0.289,0c0.313,0 0.524,0.039 0.633,0.117c0.109,0.078 0.164,0.219 0.164,0.422c0,0.214 -0.044,0.362 -0.133,0.445c-0.088,0.078 -0.258,0.118 -0.508,0.118l-2.078,-0c-0.203,-0 -0.354,-0.045 -0.453,-0.133c-0.094,-0.094 -0.14,-0.237 -0.14,-0.43c-0,-0.208 0.059,-0.349 0.179,-0.422c0.125,-0.078 0.365,-0.117 0.719,-0.117l0.156,0l0,-5.008l-0.14,0c-0.365,0 -0.615,-0.041 -0.75,-0.125c-0.131,-0.083 -0.196,-0.229 -0.196,-0.437c0,-0.183 0.052,-0.318 0.157,-0.407c0.104,-0.093 0.26,-0.14 0.468,-0.14l1.196,-0c0.172,-0 0.283,0.031 0.336,0.094c0.057,0.057 0.091,0.182 0.101,0.375c0.214,-0.23 0.443,-0.399 0.688,-0.508c0.244,-0.11 0.52,-0.164 0.828,-0.164c0.364,-0 0.664,0.08 0.898,0.242c0.24,0.156 0.417,0.396 0.531,0.719c0.219,-0.328 0.471,-0.571 0.758,-0.727c0.286,-0.156 0.614,-0.234 0.984,-0.234c0.672,-0 1.162,0.218 1.469,0.656c0.312,0.437 0.469,1.146 0.469,2.125l0,3.539l0.125,0c0.323,0 0.539,0.039 0.648,0.117c0.11,0.078 0.164,0.219 0.164,0.422c0,0.188 -0.047,0.328 -0.14,0.422c-0.094,0.094 -0.232,0.141 -0.414,0.141l-0.946,-0c-0.208,-0 -0.362,-0.063 -0.461,-0.188c-0.099,-0.125 -0.148,-0.323 -0.148,-0.594l0,-3.57Z"
        style="fill: #35f3ff; fill-rule: nonzero"
      />

      <path
        d="M110.444,6.773c-0.286,-0.093 -0.565,-0.161 -0.836,-0.203c-0.265,-0.047 -0.531,-0.07 -0.797,-0.07c-0.802,0 -1.435,0.135 -1.898,0.406c-0.464,0.271 -0.695,0.636 -0.695,1.094c0,0.354 0.143,0.646 0.429,0.875c0.292,0.229 0.662,0.344 1.11,0.344c0.661,-0 1.273,-0.172 1.836,-0.516c0.567,-0.349 0.851,-0.711 0.851,-1.086l0,-0.844Zm0.063,2.461c-0.427,0.386 -0.894,0.68 -1.399,0.883c-0.5,0.203 -1.015,0.305 -1.547,0.305c-0.776,-0 -1.403,-0.216 -1.883,-0.649c-0.473,-0.432 -0.71,-0.994 -0.71,-1.687c0,-0.823 0.315,-1.461 0.945,-1.914c0.635,-0.453 1.526,-0.68 2.672,-0.68c0.302,0 0.607,0.021 0.914,0.063c0.307,0.036 0.622,0.096 0.945,0.179l0,-0.054c0,-0.599 -0.143,-1.042 -0.43,-1.328c-0.286,-0.287 -0.731,-0.43 -1.336,-0.43c-0.406,-0 -0.859,0.109 -1.359,0.328c-0.5,0.219 -0.831,0.328 -0.992,0.328c-0.151,0 -0.276,-0.06 -0.375,-0.18c-0.099,-0.125 -0.148,-0.283 -0.148,-0.476c0,-0.307 0.286,-0.568 0.859,-0.781c0.578,-0.214 1.315,-0.321 2.211,-0.321c0.916,0 1.609,0.235 2.078,0.703c0.469,0.464 0.703,1.151 0.703,2.063l0,3.539l0.383,0c0.37,0 0.617,0.039 0.742,0.117c0.125,0.078 0.188,0.219 0.188,0.422c0,0.188 -0.053,0.328 -0.157,0.422c-0.104,0.094 -0.26,0.141 -0.468,0.141l-1.227,-0c-0.146,-0 -0.266,-0.045 -0.359,-0.133c-0.094,-0.089 -0.157,-0.216 -0.188,-0.383l-0.062,-0.477Z"
        style="fill: #35f3ff; fill-rule: nonzero"
      />

      <path
        d="M79.866,15.227c0,0.551 -0.448,1 -1,1l-21,-0c-0.551,-0 -1,-0.449 -1,-1c0,-0.552 0.449,-1 1,-1l21,-0c0.552,-0 1,0.448 1,1Z"
        style="fill: #35f3ff"
      />
    </g>
  </svg>
</template>
