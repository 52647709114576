<script setup lang="ts"></script>

<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 51 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
  >
    <g>
      <path
        d="M11.642,5.94c-0.097,-0.358 -0.239,-0.673 -0.426,-0.946c-0.188,-0.278 -0.418,-0.514 -0.69,-0.707c-0.273,-0.193 -0.586,-0.338 -0.938,-0.435c-0.352,-0.102 -0.739,-0.153 -1.159,-0.153c-0.847,-0 -1.58,0.204 -2.199,0.614c-0.614,0.409 -1.088,1.005 -1.423,1.789c-0.335,0.779 -0.503,1.725 -0.503,2.838c-0,1.12 0.162,2.074 0.486,2.864c0.324,0.79 0.792,1.392 1.406,1.807c0.614,0.415 1.358,0.622 2.233,0.622c0.773,-0 1.426,-0.125 1.96,-0.375c0.54,-0.256 0.949,-0.617 1.227,-1.082c0.279,-0.466 0.418,-1.015 0.418,-1.645l0.784,0.093l-4.244,0l-0,-3.076l7.525,-0l0,2.309c0,1.563 -0.332,2.901 -0.997,4.015c-0.659,1.113 -1.568,1.968 -2.727,2.565c-1.153,0.596 -2.48,0.895 -3.98,0.895c-1.665,-0 -3.128,-0.361 -4.389,-1.083c-1.262,-0.721 -2.245,-1.75 -2.949,-3.085c-0.705,-1.341 -1.057,-2.932 -1.057,-4.773c0,-1.431 0.213,-2.701 0.639,-3.809c0.426,-1.114 1.02,-2.057 1.781,-2.83c0.768,-0.772 1.654,-1.358 2.66,-1.755c1.011,-0.398 2.099,-0.597 3.264,-0.597c1.011,-0 1.951,0.145 2.821,0.435c0.875,0.289 1.647,0.699 2.318,1.227c0.676,0.528 1.224,1.156 1.645,1.883c0.42,0.728 0.682,1.526 0.784,2.395l-4.27,0Z"
        style="fill: #fff; fill-rule: nonzero"
      />

      <path
        d="M18.586,17.693l-0,-17.454l7.21,-0c1.307,-0 2.435,0.235 3.384,0.707c0.954,0.466 1.69,1.136 2.207,2.011c0.517,0.87 0.776,1.901 0.776,3.094c-0,1.21 -0.265,2.239 -0.793,3.085c-0.528,0.841 -1.278,1.483 -2.25,1.926c-0.972,0.438 -2.122,0.657 -3.452,0.657l-4.559,-0l-0,-3.324l3.775,-0c0.637,-0 1.168,-0.082 1.594,-0.247c0.432,-0.171 0.759,-0.426 0.98,-0.767c0.222,-0.347 0.332,-0.79 0.332,-1.33c0,-0.54 -0.11,-0.986 -0.332,-1.338c-0.221,-0.358 -0.548,-0.625 -0.98,-0.801c-0.432,-0.182 -0.963,-0.273 -1.594,-0.273l-2.079,0l-0,14.054l-4.219,0Zm9.827,-7.977l4.346,7.977l-4.602,0l-4.261,-7.977l4.517,-0Z"
        style="fill: #fff; fill-rule: nonzero"
      />

      <path
        d="M50.32,6.563l-4.261,-0c-0.057,-0.438 -0.173,-0.833 -0.349,-1.185c-0.177,-0.352 -0.41,-0.654 -0.699,-0.904c-0.29,-0.25 -0.634,-0.44 -1.032,-0.571c-0.392,-0.136 -0.826,-0.204 -1.304,-0.204c-0.846,-0 -1.576,0.207 -2.19,0.622c-0.608,0.415 -1.077,1.014 -1.406,1.798c-0.324,0.784 -0.486,1.733 -0.486,2.847c0,1.159 0.165,2.131 0.494,2.915c0.336,0.778 0.804,1.366 1.407,1.764c0.608,0.392 1.326,0.588 2.156,0.588c0.466,-0 0.889,-0.06 1.27,-0.179c0.386,-0.119 0.724,-0.293 1.014,-0.52c0.295,-0.233 0.537,-0.514 0.724,-0.844c0.194,-0.335 0.327,-0.713 0.401,-1.133l4.261,0.025c-0.074,0.773 -0.298,1.534 -0.673,2.284c-0.369,0.75 -0.878,1.435 -1.526,2.054c-0.647,0.614 -1.437,1.103 -2.369,1.466c-0.926,0.364 -1.989,0.546 -3.187,0.546c-1.58,-0 -2.995,-0.347 -4.245,-1.04c-1.244,-0.699 -2.227,-1.716 -2.949,-3.051c-0.721,-1.335 -1.082,-2.96 -1.082,-4.875c0,-1.921 0.367,-3.548 1.099,-4.884c0.733,-1.335 1.725,-2.349 2.975,-3.042c1.25,-0.693 2.65,-1.04 4.202,-1.04c1.056,0 2.034,0.148 2.931,0.443c0.898,0.29 1.688,0.716 2.37,1.279c0.682,0.556 1.236,1.241 1.662,2.054c0.426,0.812 0.69,1.741 0.792,2.787Z"
        style="fill: #fff; fill-rule: nonzero"
      />
    </g>
  </svg>
</template>
