<script setup lang="ts">
import type { Component, PropType } from "vue";

const props = defineProps({
  href: {
    type: String,
    default: "",
  },
  active: {
    type: Boolean,
    required: false,
  },
  label: {
    type: String,
    default: "",
  },
  expanded: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: Object as PropType<Component>,
    required: true,
  },
});

const classes = computed(() => (props.active ? "bg-gray-700 font-bold" : "font-light"));
const barColor = computed(() => (props.active ? "bg-schema" : ""));
</script>

<template>
  <NuxtLink :to="href" class="relative block text-white hover:bg-primary-lighter" :class="classes">
    <div class="absolute h-full w-2" :class="barColor" />

    <div class="side-nav-py overflow-clip px-7">
      <div class="flex w-72 items-center justify-start gap-x-3 overflow-clip">
        <Component :is="icon" class="h-5 w-5 flex-none fill-white" />

        <span class="font-light transition-all" :class="{ 'w-60': expanded, 'opacity-0': !expanded }">
          {{ label }}
        </span>
      </div>
    </div>
  </NuxtLink>
</template>
