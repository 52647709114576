<script setup lang="ts">
import TableOfContentsItem from "~/components/docs/TableOfContentsItem.vue";

const { data: navigation } = await useAsyncData("navigation", () => fetchContentNavigation(queryContent("/docs")));

const docs = ref(navigation.value[0].children);
</script>

<template>
  <div>
    <TableOfContentsItem v-for="link in docs" :key="link._path" :nav-item="link" />
  </div>
</template>
