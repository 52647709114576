<script setup lang="ts">
import NavLink from "~/components/nav/NavLink.vue";
import type NavLinkType from "~/types/NavLink";
import { computed } from "vue";
import FileIcon from "~/components/icons/FileIcon.vue";
import NavCollapseButton from "~/components/nav/NavCollapseButton.vue";
import DividerBar from "~/components/nav/DividerBar.vue";
import { useSideNavStore } from "~/store/sideNavStore";
import ThingsIcon from "~/components/icons/ThingsIcon.vue";
import PropertiesIcon from "~/components/icons/PropertiesIcon.vue";
import DataTypes from "~/components/icons/DataTypes.vue";
import PrefixIcon from "~/components/icons/PrefixIcon.vue";
import SuffixIcon from "~/components/icons/SuffixIcon.vue";
import AlphaC from "~/components/icons/AlphaC.vue";
import TableOfContents from "~/components/docs/TableOfContents.vue";
import MessageIcon from "~/components/icons/MessageIcon.vue";

const sideNavExpanded = computed(() => useSideNavStore().expanded);

const route = useRoute();
const navLinks = computed((): NavLinkType[] => [
  {
    label: "Things",
    icon: ThingsIcon,
    visible: true,
    href: "/thing",
    active: route.fullPath === "/thing",
  },
  {
    label: "Properties",
    icon: PropertiesIcon,
    visible: true,
    href: "/property",
    active: route.fullPath === "/property",
  },
  {
    label: "DataTypes",
    icon: DataTypes,
    visible: true,
    href: "/datatype",
    active: route.fullPath === "/datatype",
  },
  {
    label: "Character",
    icon: AlphaC,
    visible: true,
    href: "/character",
    active: route.fullPath === "/character",
  },
  {
    label: "Name Prefix",
    icon: PrefixIcon,
    visible: true,
    href: "/prefix",
    active: route.fullPath === "/prefix",
  },
  {
    label: "Name Suffix",
    icon: SuffixIcon,
    visible: true,
    href: "/suffix",
    active: route.fullPath === "/suffix",
  },
]);
</script>

<template>
  <div>
    <div v-for="link in navLinks" :key="link.label">
      <!-- Single link -->
      <NavLink
        v-if="'href' in link && link.visible"
        :href="link.href"
        :active="link.active ?? false"
        :label="link.label"
        :expanded="sideNavExpanded"
        :icon="link.icon"
      />
      <!-- Link with children -->
      <NavCollapseButton
        v-if="'children' in link && link.visible"
        :label="link.label"
        :expanded="sideNavExpanded"
        :icon="link.icon"
        :active="link.active"
        :children="link.children"
      />
    </div>

    <DividerBar class="my-12" />
    <!-- Docs table of content  -->
    <NavLink
      href="/message-board"
      :active="route.fullPath === '/message-board'"
      label="Message Board"
      :expanded="sideNavExpanded"
      :icon="MessageIcon"
    />

    <DividerBar class="my-12" />
    <!-- Docs table of content  -->
    <NavLink
      href="/docs"
      :active="route.fullPath.includes('docs')"
      label="Docs"
      :expanded="sideNavExpanded"
      :icon="FileIcon"
    />

    <div class="box-border flex flex-col overflow-hidden text-white" v-if="sideNavExpanded">
      <TableOfContents />
    </div>
  </div>
</template>
